.table {
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border-collapse: collapse;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  table-layout: fixed;
  overflow-y: scroll;
  max-width: calc(100vw - 220px);
  min-width: 100%;
  margin-top: 30px;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background: rgb(245, 248, 250);
}

.tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.th {
  min-width: 210px;
  padding: 0px 11px 0px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  position: sticky;
  top: -1px;
  font-size: 11px;
  font-weight: 800;
  color: #222222;
  border: 1px solid #dde5ef;
  border-left-width: 0px;
  border-right-width: 0px;
  background: white;
  /* background: rgb(245, 248, 250); */
}

.tbody {
  border-color: inherit;
  overflow-y: scroll;
  overflow-x: scroll;
}

.tr {
  transition: background-color 150ms ease-out 0s;
}

.td {
  hyphens: auto;
  overflow-wrap: break-word;
  padding: 13px 0px;
  vertical-align: middle;
  border-color: rgb(223, 227, 235);
  border-style: solid;
  border-width: 0px 0px 0px;
  line-height: normal;
  border-bottom: 1px solid rgb(223, 227, 235);
  max-height: 35px;
  box-sizing: border-box;
}

.td>p {
  margin: 0
}