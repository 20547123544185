.headerContainer {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #E9E9E9;
}

.headerIconContainer {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    background: #565656;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContainer>h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #1E1E1E;
    margin-bottom: 0px;
    margin-left: 20px;
}

.subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
    margin-top: 30px;
}

.subtitle2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
    margin-top: 25px;
}

