.spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.25)
    rgb(0, 0, 0);
  transform: translateZ(0px);
  animation: UdTIS 0.8s linear 0s infinite normal none running;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  position: relative;
  width: 48px;
  height: 48px;
  border-width: 2px;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}

.loading {
  box-sizing: border-box;
  margin: 30px 0px 0px;
  min-width: 0px;
}

.loading > p {
  font-weight: 600;
  line-height: 1.5;
  font-size: 16px;
  color: rgb(0, 0, 0);
  text-align: left;
  text-transform: none;
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
  margin: 0px;
}

@keyframes UdTIS {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
